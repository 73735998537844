import { Payment, PaymentMethodType, PSPType } from 'app/models/api/payment';
import { differenceInDays, differenceInMonths, differenceInWeeks } from 'date-fns';
import { Child } from 'app/models/api/child';
import { Photo, UploadAvatarErrorMeta } from 'app/models/api/photo';
import { Recommendation } from 'app/models/api/recommendation';
import { Reference } from 'app/models/api/reference';
import { BaseApiModel } from 'app/models/api/response';
import { Warning, WarningLevel } from 'app/models/api/warning';
import { AllWeightsTypes } from 'app/components/user-profile/matches-tab/matches-tab.component';
import { DayPart, WeekDay } from 'app/types';

export type UserAvailabilityInterface = {
    [day in WeekDay]: UserDayAvailabilityInterface;
};

export type UserDayAvailabilityInterface = {
    [day in DayPart]: boolean;
};

export interface SearchPreferencesInterface {
    maxDistance: number;
}

export interface ParentSearchPreferencesInterface extends SearchPreferencesInterface {
    babysitters: boolean;
    childminders: boolean;
    parent: boolean;
    afterSchool: boolean;
    occasionalCare: boolean;
    regularCare: boolean;
    playdate: boolean;
    fosterLocation: {
        visit: boolean;
        receive: boolean;
    };
    availability: UserAvailabilityInterface;
    languages: Language[];
    gender: Gender;
    hourlyRates?: string[];
    chores?: FosterChores[];
}

export interface FosterSearchPreferencesInterface extends SearchPreferencesInterface {
    age: {
        min: number;
        max: number;
    };
    maxChildren: number;
}

export interface FosterProperties {
    isAvailableAfterSchool: boolean;
    isAvailableOccasionally: boolean;
    isAvailableRegularly: boolean;
    isRemoteTutor: boolean;
    isExperienced: boolean;
    isEducated: boolean;
    hasReferences: boolean;
    occupation: Occupation;
    availableFromDate: string;
    yearsOfExperience: YearsExperience;
    ageGroupExperience: {
        '0': boolean;
        '1-3': boolean;
        '4-6': boolean;
        '7-11': boolean;
        '12plus': boolean;
    };
    languages: Language[];
    averageHourlyRate: string;
    isSmoker: boolean;
    nativeLanguage: Language;
    fosterChores: {
        chores: boolean;
        driving: boolean;
        shopping: boolean;
        cooking: boolean;
        homework: boolean;
    };
    fosterLocation: {
        visit: boolean;
        receive: boolean;
    };
    availability: UserAvailabilityInterface;
    location: {
        visit: boolean;
        receive: boolean;
    };
    hasFirstAidCertificate?: boolean;
    hasCertificateOfGoodBehavior?: boolean;
    hasDriversLicense?: boolean;
    hasCar?: boolean;
    skills?: FosterSkill[];
    traits?: FosterTrait[];
}

export enum FosterChores {
    homework = 'homework',
    shopping = 'shopping',
    cooking = 'cooking',
    driving = 'driving',
    chores = 'chores',
}
export const allFosterChores = Object.values(FosterChores);

export enum Occupation {
    scholar = 'scholar',
    student = 'student',
    unemployed = 'unemployed',
    employed = 'employed',
    householder = 'householder',
}
export const allOccupationOptions = Object.values(Occupation);

export enum FosterSkill {
    art = 'art',
    music = 'music',
    baking = 'baking',
    sports = 'sports',
    games = 'games',
    storytelling = 'storytelling',
}
export const allFosterSkills = Object.values(FosterSkill);

export enum FosterTrait {
    calm = 'calm',
    patient = 'patient',
    enthusiastic = 'enthusiastic',
    kind = 'kind',
    caring = 'caring',
    creative = 'creative',
    funny = 'funny',
    talkative = 'talkative',
    strict = 'strict',
    tolerant = 'tolerant',
}
export const allFosterTraits = Object.values(FosterTrait);

export enum UserRole {
    parent = 'parent',
    babysitter = 'babysitter',
    childminder = 'childminder',
}

export enum UserRoleId {
    parent = 1,
    babysitter = 2,
    childminder = 3,
}
export const allUserRoleIds = [UserRoleId.parent, UserRoleId.babysitter, UserRoleId.childminder];

export enum Gender {
    female = 'female',
    male = 'male',
    unknown = 'unknown',
}

export interface Language {
    code: string;
    name: string;
    localName: string;
    isCommon?: boolean;
}

export enum OnlineStatus {
    online = 'online',
    away = 'away',
    offline = 'offline',
}

export enum LastSeenStatus {
    online = 'online',
    today = 'today',
    yesterday = 'yesterday',
    twoDaysAgo = 'twoDaysAgo',
    threeDaysAgo = 'threeDaysAgo',
    fourDaysAgo = 'fourDaysAgo',
    fiveDaysAgo = 'fiveDaysAgo',
    sixDaysAgo = 'sixDaysAgo',
    weekAgo = 'weekAgo',
    twoWeeksAgo = 'twoWeeksAgo',
    threeWeeksAgo = 'threeWeeksAgo',
    monthAgo = 'monthAgo',
    greaterThanTwoMonthsAgo = 'greaterThanTwoMonthsAgo',
}

export enum YearsExperience {
    none = '0',
    one = '1',
    two = '2',
    three = '3',
    four = '4',
    five = '5',
    moreThanFive = '5plus',
}

export class User extends BaseApiModel {
    role?: UserRole;
    email?: string;
    firstName?: string;
    lastName?: string;
    about?: string;
    created?: string;
    updated?: string;
    lastLogin?: string;
    latitude?: number;
    longitude?: number;
    localeCode?: string;
    placeName?: string;
    streetName?: string;
    houseNumber?: string;
    postalCode?: string;
    notes?: string;
    gender?: Gender;
    age?: number;
    education?: string;
    averageRecommendationScore?: number;
    availabilityUpdated?: string;
    birthdate?: string;
    isPremium?: boolean;
    premiumExpiryDate?: Date;
    gracePeriod?: string;
    subscriptionCancelled?: boolean;
    subscriptionCancellationDate?: string;
    subscriptionPsp?: PSPType;
    paymentMethod?: PaymentMethodType;
    availableForChat?: boolean;
    receiveMatchMail?: string;
    lastSearchActivity?: string;
    publicProfileUrl?: string;
    avatarWarnings?: UploadAvatarErrorMeta;
    unsuitablePhotoReason?: string;
    hasPublicProfile?: boolean;
    shareProfileWithPartners?: boolean;
    disabledSafetyMessages?: boolean;
    completed?: boolean;
    disabled?: boolean;
    deleted?: boolean;
    quarantinedAt?: string;
    discountOfferedDate?: string;
    discountPercentage = 0;
    premiumExtensionUsed: 0 | 1 = 0;
    active = true;
    inappropriate = false;
    suspected = false;
    // relationships
    searchPreferences?: FosterSearchPreferencesInterface | ParentSearchPreferencesInterface;
    fosterProperties?: FosterProperties;
    accessToken?: string;
    children: Child[] = [];
    references: Reference[] = [];
    photos: Photo[] = [];
    recommendations: Recommendation[] = [];
    similarUsers: User[] = [];
    warnings: Warning[] = [];
    payments: Payment[] = [];

    meta?: {
        potentialNonresponder: boolean;
        inviteToApply: boolean;
        distance: {
            kilometers: number;
        };
        conversationsCount: number;
        replacedAbout: string;
        aboutReplacements: string[];
        relevanceSortingStats: {
            match: number;
            weights: Record<keyof AllWeightsTypes, number>;
        };
    };
    links?: {
        avatar: string;
    };

    get isPotentialNonResponder() {
        return this.meta?.potentialNonresponder;
    }
    get canBeInvitedToApply() {
        return this.meta?.inviteToApply;
    }
    get distance() {
        return this.meta?.distance?.kilometers ?? 0;
    }

    get canCancelPremium() {
        return (
            !this.subscriptionCancelled && this.subscriptionPsp === PSPType.adyen && this.paymentMethod !== PaymentMethodType.welfareVoucher
        );
    }

    get isParent() {
        return this.role === UserRole.parent;
    }
    get isChildminder() {
        return this.role === UserRole.childminder;
    }
    get isBabysitter() {
        return this.role === UserRole.babysitter;
    }

    get isMale() {
        return this.gender === Gender.male;
    }
    get isFemale() {
        return this.gender === Gender.female;
    }

    get availability() {
        return (this.isParent ? (this.searchPreferences as ParentSearchPreferencesInterface) : this.fosterProperties)?.availability;
    }

    get occasionalAvailability() {
        return this.isParent
            ? (this.searchPreferences as ParentSearchPreferencesInterface)?.occasionalCare
            : this.fosterProperties?.isAvailableOccasionally;
    }
    get afterSchoolAvailability() {
        return this.isParent
            ? (this.searchPreferences as ParentSearchPreferencesInterface)?.afterSchool
            : this.fosterProperties?.isAvailableAfterSchool;
    }
    get regularAvailability() {
        return this.isParent
            ? (this.searchPreferences as ParentSearchPreferencesInterface)?.regularCare
            : this.fosterProperties?.isAvailableRegularly;
    }

    get onlineStatus(): OnlineStatus {
        if (!this.lastActivityDate) {
            return OnlineStatus.offline;
        }

        const now = new Date();
        const date = new Date(this.lastActivityDate);

        const days = differenceInDays(now, date);
        if (days < 1) {
            return OnlineStatus.online;
        }

        const weeks = differenceInWeeks(now, date);
        if (weeks <= 3) {
            return OnlineStatus.away;
        }

        return OnlineStatus.offline;
    }

    get lastSeenStatus(): LastSeenStatus {
        if (!this.lastActivityDate) {
            return LastSeenStatus.greaterThanTwoMonthsAgo;
        }

        const now = new Date();
        const date = new Date(this.lastActivityDate);

        const days = differenceInDays(now, date);
        if (days < 1) {
            return LastSeenStatus.today;
        } else if (days < 2) {
            return LastSeenStatus.yesterday;
        } else if (days < 3) {
            return LastSeenStatus.twoDaysAgo;
        } else if (days < 4) {
            return LastSeenStatus.threeDaysAgo;
        } else if (days < 5) {
            return LastSeenStatus.fourDaysAgo;
        } else if (days < 6) {
            return LastSeenStatus.fiveDaysAgo;
        } else if (days < 7) {
            return LastSeenStatus.sixDaysAgo;
        }

        const weeks = differenceInWeeks(now, date);
        if (weeks < 2) {
            return LastSeenStatus.weekAgo;
        } else if (weeks < 3) {
            return LastSeenStatus.twoWeeksAgo;
        } else if (days < 4) {
            return LastSeenStatus.threeWeeksAgo;
        }

        const months = differenceInMonths(now, date);
        if (months < 2) {
            return LastSeenStatus.monthAgo;
        }

        return LastSeenStatus.greaterThanTwoMonthsAgo;
    }

    get lastActivityDate() {
        return this.lastSearchActivity ?? this.created;
    }
    get lastLoginDate() {
        return this.lastLogin ?? this.created;
    }

    get warningLevel() {
        if (this.warnings.some(item => item.warningLevel === WarningLevel.blocked)) {
            return WarningLevel.blocked;
        } else if (this.warnings.some(item => item.warningLevel === WarningLevel.severe)) {
            return WarningLevel.severe;
        } else if (this.warnings.some(item => item.warningLevel === WarningLevel.moderate)) {
            return WarningLevel.moderate;
        }
        return null;
    }

    get statusRedFlag() {
        return !this.active || this.inappropriate;
    }

    get statusYellowFlag() {
        return this.warningLevel === WarningLevel.moderate || (this.notes?.length ?? 0) > 0;
    }

    get deepCopy() {
        return Object.assign(new User(), JSON.parse(JSON.stringify(this))) as User;
    }
}
